import Home from "./pages/Home";
import Header from "./components/header/Header";
import { Route, Routes } from "react-router-dom";
import Proyect from "./pages/Proyect";

function App() {
  return (

    <>  <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="proyecto/:proyect" element={<Proyect />} />
      </Routes>
    </>

  );
}

export default App;
