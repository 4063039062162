const cardDb =[
    {
        id: 1 ,
        service:'Design',
        detail:"We have an experienced and flexible team who will help you to materialize your proyect."
    },
    {
        id: 2 ,
        service:'Web development',
        detail:"Our team develop the code in the most practical, sustainable and scalable way. They work along with the design team to ensure a high quality product."
    },
    {
        id: 3 ,
        service:'App development',
        detail:"Our team develop the code in the most practical, sustainable and scalable way. They work along with the design team to ensure a high quality product."
    }
]

export default function CardInfo(){
    return(cardDb)
}