const proyectsDb = [
    {
        id: 1,
        name:"La Jaula",
        proyectType:"Self-managed Web Design and Development",
        about:'We work in the main problem, creat a web focus on the user. They could view their progress at the tournament and got all kind of usefull information. On the other hand, the web owners got his oun dashboard where they can control and edit all the information.',
        mobilImg: 'LaJaula/iPhone 12 Mockup - 1.png',
        desktopImg:'LaJaula/MacBook Air - 1.png',
        carrusell:[
            'LaJaula/home.png',
            'LaJaula/la-jaula-bar.png',
            'LaJaula/la-jaula-tv.png',
            'LaJaula/partido-del-dia.png'

        ]
    },

    {
        id: 2,
        name:"Icrea",
        proyectType:"Brand and Web Design - Web Development",
        about:'This proyect was all about the brand and getting leads. First we worked on the brand. We took the concept of partner and made a functional and adaptable Logo. Once finished, we created a flashy website to capture new users.',
        mobilImg: 'icrea/ICREA-iPhone 12 Mockup.png',
        desktopImg:'icrea/ICREA MacBook Air.png',
        carrusell:[
            'icrea/HOME.png',
            'icrea/COMO-TRABAJAMOS.png',
            'icrea/INICIOS.png',
            'icrea/QUIENES-SOMOS.png',
            
        ]
    },
    {
        id: 3,
        name:"Buddy",
        proyectType:"Brand design - App Design - Web design & development",
        about:'Buddy was a big challenge. We where working and testing all king of thing simultaneously. At first we creat the logo and the brand image. After that we work in web-app feature. We successfuly achive all the client demand.',
        mobilImg: 'buddy/BUDDY iPhone 12 Mockup.png',
        desktopImg:'buddy/BUDDY MacBook Air.png',
        carrusell:[
            'buddy/perfil.png',
            'buddy/comentarios.png',
            'buddy/alerta.png',
            'buddy/formulario.png',

            

        ]
    },

]
export function Proyectos(){
    return(proyectsDb)
}
export function FindProyect(name){
return(
    proyectsDb.find(parametro=> parametro.name === name))
}