import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import '../assets/global.css'
import Footer from "../components/footer/Footer";
import CardService from "../components/card-service/CardService";

import flecha from '../assets/flecha.svg';
import CardInfo from "../data/card-db";
import {Proyectos} from "../data/proyects-db";
import MailTo from "../components/mail/MailTo";


export default function Home() {
    const infoCard = CardInfo();
    const proyectos = Proyectos();

    return (
        <>
            <main>
                <div className="info-extra">
                    <h4>Buenos Aires <br /> Argentina</h4>
                    <h4>Web Development <br />App Development</h4>
                </div>
                <section className="titular">
                    <h1 className="texto-titular">Welcome to the <strong>FUTURE</strong>. Create your web and <strong>expand your business</strong>.</h1>
                    <button> <a href="#our-services"> learn more</a></button>
                    <br />
                    <img src={flecha} alt="Icono Flecha" />
                </section>
                <section className="titular-2" id={"our-services"}>
                    <h2 className="titular-2-texto">Your future is one click away.</h2>
                    <p>We are the future of
                        your business. We will help you
                        to show your brand
                        in a different way.</p>
                </section>
                <section className="flex sec-card">
                    {
                        infoCard.map((card)=><CardService key={card.id} service={card.service} detail={card.detail}/>)
                    }
                    
                </section>
                <div className="descripcion">
                    You're gonna <strong>FIND AN ALLY</strong> at Juicy. We make everything with
                    <strong> PASSION</strong>. We're <strong>OBSESSED</strong> with technology, details
                    and being updated. We're the<strong> TEAM</strong> you are looking for.
                </div>
                <section>
                    <div>
                        <h1 className="descripcion cierre">Always making <strong>Juicy </strong> designs</h1>
                    </div>
                </section>
                <section id={"proyectos"} className="proyectos">
                    <h3>Our Proyects</h3>
                    <ul>
                        {
                            proyectos.map((proyecto)=><li key={proyecto.id}><Link to={`proyecto/${proyecto.name}#top`}>{proyecto.name} - {proyecto.proyectType}</Link></li>)
                        }
                    </ul>
                </section>
                <div className="info-contacto" id="contacto">
                    <MailTo  email="hi@juicy.com" subject="" body="">
                        hi@juicy.com.ar
                    </MailTo>

{    /*              <form action="POST">
                        <h3>Let's work together</h3>
                        <div className="campos-formulario">
                            <input type="text" placeholder="Name" />
                            <input type="text" placeholder="Mail" />
                            <input type="text" placeholder="Company Name" />
                            <input type="text" placeholder="Phone Number" />
                        </div>
                        <button>send</button>
                    </form>*/}
                    <div className="info-extra-contacto">
                        <h4>Buenos Aires <br /> Argentina <br /> +54 9 1169485194</h4>
                        <h4>Web Development <br />App Development <br /> Software</h4>
                    </div>
                </div>

            </main>
            <Footer />
        </>
    )
}