import './proyecto.css'
import { useParams } from 'react-router-dom';
import { FindProyect } from '../data/proyects-db';
import Footer from "../components/footer/Footer"
import { HashLink } from 'react-router-hash-link';

export default function Proyect() {
    const params = useParams();
    const proyecto = FindProyect(params.proyect);
    console.log(proyecto)


    return (
        <>
            <main className="contenedor">
                <div className='proyecto'>
                    {/* 
                    <div className='proyecto-boton'>
                        <button>web</button>
                        <button>dashboard</button>
                    </div>
                    */}
                    <div className="proy-info" >
                        <h1 className="nombre-proyecto">{proyecto.name}</h1>
                        <h2 className="trabajo-proyecto">{proyecto.proyectType}</h2>
                        <p className="descripcion-proyecto">{proyecto.about}</p>
                    </div>
                </div>
                <div className="proy-content">
                    <div className='content-titular'>
                        <h2>Mobile version</h2>
                    </div>
                    <div className='content-img'>
                        <img src={`/img/${proyecto.mobilImg}`} alt='Mockup-mobile' />
                    </div>
                    <div className='content-titular-2'>
                        <h2>Desktop version</h2>
                    </div>
                    <div className='content-img-2'>
                        <img src={`/img/${proyecto.desktopImg}`} alt='Mockup-mobile' />
                    </div>
                    <div className='capturas-pantalla'>
                        {
                            proyecto.carrusell.map((img)=> <img src={`/img/${img}`} alt='captura pantalla la jaula'/>)
                        }
                    </div>
                </div>
                <section className='contactanos'>
                    <h3>You like what you see?</h3>
                    <HashLink smooth to="/#contacto">
                    <button>Let's work together</button>
                    </HashLink>
                    
                </section>
            </main>
            <Footer/>
        </>
    )
}