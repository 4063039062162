import React from 'react';
import './mailto.css';

export default function MailTo({ email, subject, body, ...props }) {
    return (
        <div>
            <h1 className='mailto-title'>
                Contact us
            </h1>
            <div className='mailto-link'>
                <a  href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
                    {props.children}
                </a>
            </div>
        </div>


    )
}
