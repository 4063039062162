import { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import '../../assets/global.css';
import toggle from '../../assets/Grupo 10.svg';
import x from '../../assets/exit.svg';
import LogoInicio from "../logo-inicio/LogoInicio";

export default function Header() {
    const [sidebar, setsidebar] = useState(false);
    const showsidebar = () => setsidebar(!sidebar);

    return (
        <header>
            <div className="menu">
                <LogoInicio/>
                <img onClick={showsidebar} className="toggle" src={toggle} alt="toggle" />
            </div>
            <div onClick={showsidebar} className={sidebar ? 'menu-links active' : 'menu-links'}>
                <img className="exit" src={x} alt="icono x" />
                <ul id="links">

                    <li><Link onClick={showsidebar} activeClass="active"
                        to="/#our-services">Our Services</Link>
                    </li>
                    <li><Link onClick={showsidebar} activeClass="active"
                        to="/#proyectos">Clients</Link>
                    </li>
                    <li><Link onClick={showsidebar} activeClass="active"
                        to="/#contacto">Work with us</Link>
                    </li>

                </ul>
            </div>

        </header>
    )
}