import { useState } from 'react';
import './cardService.css';

export default function CardService(props) {
    const [hover, sethover] = useState()
    const showHover = () => sethover(!hover)
    return (
        <>
            <div onClick={showHover} className={hover ? 'card-service active-card' : 'card-service'}>
                <h1>{props.service}</h1>
                <p className={hover ? 'card-info card-info-active' : 'card-info'}>{props.detail} </p>
            </div>
        </>
    )
}